<template>
  <v-app>
    <span class="bg" v-if="isAuthenticated==false"></span>
    <v-layout>
      <v-app-bar 
        v-if="isAuthenticated"
        color="primary"
      dense
      dark
        >
        <img class="mr-3" :src="require('@/assets/logo-keygene.svg')" height="40"/>
           <v-toolbar-title>{{ app_title }}</v-toolbar-title>
      <v-spacer/>
      <v-menu>

        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link to="/upload">
            <v-list-item-title>upload images</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/logout">
            <v-list-item-title>logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
      </v-app-bar>
      <v-navigation-drawer
        v-if="isAuthenticated"
      >

      <Joblist></Joblist>

      </v-navigation-drawer>

      <v-main style="min-height: 300px;">
        <router-view></router-view>
      </v-main>
    </v-layout>


</v-app>
</template>

<script>

import { mapGetters } from 'vuex';

import Joblist from '@/components/partials/Joblist'

export default {
  name: 'App',
  components: {
    Joblist
  },
  computed: {
    ...mapGetters('auth',[
            'isAuthenticated',
            'currentUser'
        ]),
  },
  data() {
    return {
      app_title: "Portal app: Nematode detection - beta",
    }
  }
}
</script>

<style>

.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url( '../public/keygene-header-tulip.png') no-repeat center center;
    background-size: cover;
    transform: scale(1.1);
}
</style>
