<template>
  <v-container>
        <v-form @submit.prevent>
          <v-row>
            <v-col>
              <h2 class="font-weight-light primary--text">Enter a name for this analysis</h2>

              <v-text-field
                  label="Job name"
                  prepend-icon="mdi-file-cog"
                  type="text"
                  hide-details="auto"
                  hint="Enter an identifier for this job"
                  :rules="[rules.run_name,rules.required]"
                  v-model="job_name"
                  color="primary"
                  variant="outlined"
                ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div id="reader" width="250px"></div>
            </v-col>

          </v-row>

          <v-row>
            <v-col>
          <h2 class="font-weight-light primary--text">Select image(s)
            <v-chip
              class="ma-2"
              color="default"
              variant="outlined"
            >
              <v-icon start icon="mdi-image-area"></v-icon>
              jpeg only
            </v-chip>
          </h2>


          <v-file-input
            id="qr-input-file"
            v-model="files"
            color="primary"
            counter
            label="Select files"
            multiple
            placeholder="Select your files"
            prepend-icon="mdi-image"
            variant="outlined"
            :show-size="1000"
            :rules="[rules.required]"
            @change="scanFiles"
            accept="image/jpeg"
            capture="environment"
          >
            <template v-slot:selection="{ fileNames }">
              <template v-for="(fileName, index) in fileNames" :key="fileName">
                <v-chip
                  v-if="index < 4"
                  color="deep-purple-accent-4"
                  label
                  size="small"
                  class="me-2"
                >
                  {{ fileName }}
                </v-chip>

                <span
                  v-else-if="index === 4"
                  class="text-overline text-grey-darken-3 mx-2"
                >
                  +{{ files.length - 4 }} File(s)
                </span>
              </template>
            </template>
          </v-file-input>
            </v-col>
          </v-row>
          
          <v-row v-if="pre_processing_started">
            <v-col>
              <v-card>
                <v-card-item>
                  <v-card-title>Selected images for upload:</v-card-title>
                  <v-card-subtitle><strong>{{ selected_images }}</strong> image(s) selected ({{ upload_size.toFixed(2) }} MiB)</v-card-subtitle>
                </v-card-item>
                <v-card-text>
                  <v-row>
                    <v-col sm="2" v-for="(file,f) in filtered_files" :key="f">
                      <div v-if="create_previews== true"  class="container img-container" :id="'upld_' + f">
                        <img :ref="'file'" src="" class="img-fluid" :title="'file' + f"  />
                        <div class="centered">{{file.name}}</div>
                      </div>
                      <div v-else :id="'upld_' + f">
                        {{file.name}}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-progress-linear
                  :active="! pre_processing_done"
                  :model-value="value"
                  rounded
                  color="primary"
                ></v-progress-linear>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="error_text">
            <v-col>
              <v-alert
                density="compact"
                type="error"
                closable
                title="Error encountered"
                :text="error_text"
                v-model="error_text"
              ></v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-alert
              type="info"
              title="Note"
              color="default"
              text="Thank you for trying the nematode detection portal. This software is currently in beta, meaning that (techinical) issues 
              can arrise. When you see unexpected behaviour, please let us know so we can use it to improve this software."
            ></v-alert>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                prepend-icon="mdi-cloud-arrow-up"
                @click="upload()"
                :disabled="!pre_processing_done"
              >
              Upload images</v-btn>
            </v-col>

            <v-col>
              <v-btn
                type="submit"
                color="warning"
                prepend-icon="mdi-restore"
                @click="reset()"
              >
              reset</v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-container>
</template>

<script>

import axios from 'axios'
import authHeader from '@/services/authHeader';
import endpoint from '@/store/endpoint.js';

import {v4 as uuidv4} from 'uuid';

import {Html5Qrcode} from "html5-qrcode";

export default {
  name: "upload-image",
  data() {
    return {
      showCamera: true,
      isCameraOpen: false,
      isPhotoTaken: false,
      files: [],  // all files uploaded
      filtered_files: [],    // files selected by filter
      qr_files: [],
      qr_content: {},
      readers: [],
      job_name: (Math.random() + 1).toString(36).substring(2),
      image_interval: 16, // use the n-th image after a QR code is found
      job_id: uuidv4(), // randam job id based on UUID
      rules: {
          required: value => !!value || 'Required.',
          run_name: value => value.length <= 30 || 'Max 30 characters',
      },
      status: '',
      value: 0,
      interval: 0,
      pre_processing_done: false,
      selected_images: 0,
      upload_size: 0,
      pre_processing_started: false,
      do_filter: false,
      create_previews: false,
      error_text: null,
      html5QrCode: null,
      devices: null,
    };
  },
  watch: {
    do_filter() {
      this.create_previews = this.do_filter == false ? false : true 
    }
  },
  mounted () {
    let config = {
      fps: 60,
      verbose: true
    };
    this.html5QrCode = new Html5Qrcode("reader", config, true);

    //this.getCameraSelection()
  },
  beforeUnmount () {
    clearInterval(this.interval)
  },
  methods: {
    onScanSuccess(decodedText, decodedResult) {
      // handle the scanned code as you like, for example:
      console.log(`Code matched = ${decodedText}`, decodedResult);
    },
    onScanFailure(error) {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      console.warn(`Code scan error = ${error}`);
    },
    sortAlphabetically() {
      // ensure the files are naturally sorted
      this.files  = this.files.sort(function(a, b) {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
    },
    scanFiles() {
      this.sortAlphabetically();
      this.pre_processing_started = true

      if(this.do_filter == false) {
        for(let i = 0; i < this.files.length; i++) {
          this.save_image( this.files[i] )
        }
        this.pre_processing_done = true;
      }
      else {
        this.process_files();
      }
    },
    async process_files() {
      this.interval = 100 / this.files.length;  // used to calculate progress bar

      let cur_step = 1; // first pass
      let do_scan = true;

      for(let i = 0; i < this.files.length; i++) {
        
        if(do_scan == true) {
          let hasQR = await this.scanFilesForQR(this.files[i])
          if(hasQR == true) {
            cur_step = 0; // start counter
            this.save_image( this.files[i] )
          } 
          do_scan = false;
        }

        if(cur_step == this.image_interval) {
          this.save_image( this.files[i] )
          do_scan = true;
          cur_step = 0;
        }
        cur_step++;
        this.value += this.interval
      } 

      this.status = "pre-processing finished. Ready for upload."
      this.pre_processing_done = true;
    },
    save_image(file) {
      console.log('store image ' + file.name)
      this.filtered_files.push(file)
      this.addFile( file, this.filtered_files.length -1 )
      this.selected_images ++
    },
    async scanFilesForQR(file) {
      let hasQR = false;

      await this.html5QrCode.scanFileV2(file, false)
      .then(decodedText => {
          console.log(decodedText)
          this.status = "Found QR code in " + file.name
          this.qr_content[file.name] = decodedText // store QR contents
          this.qr_files.push(file)
          hasQR = true
      })
      .catch(err => {
        // failure, handle it.
        console.log(`No QR found. Reason: ${err}`)
      });
        return hasQR
    },
    addFile(file, f) {
      this.status = "adding file " + file.name + " to upload queue"
      console.log(this.status)
      
      if(this.create_previews == true) {
        let thumb = new FileReader();
        thumb.onloadend = () => {
            let fileData = thumb.result
            let imgRef = this.$refs.file[f]
            imgRef.src = fileData
        }
        thumb.readAsDataURL(file);
        this.readers.push(thumb)
      }
      // calculate size
      this.upload_size += file.size /1024 /1024
    },
    async upload() {
      console.log(this.filtered_files)
      try {
        this.filtered_files.forEach((file, f) => {
          if( typeof this.qr_content[file.name] !== 'undefined') {
            this.createQRFile(file.name).then( response => {
              this.handleResponse(response, f)
            });
          }
          else {
            this.send_file(file).then( response => {
              this.handleResponse(response, f)
            });
          }
        })
      }
      catch(E) {
        console.log(E)
        return false
      }
      this.files = []
      this.status = "Upload complete."
    },
    handleResponse(response, f) {
      if(typeof response === 'undefined') {
        this.error_text = `Uploading images have failed. Please create a screenshot of this page, and send to support. 
        Number of uploadable images: ${ this.selected_images }. Upload size: ${ this.upload_size.toFixed(2) } MiB. 
        job_id: ${ this.job_id }`
        return
      }
      if(response.status == 200) {
        try {
          if( response.data.status == 'HTTP_200_OK' ) {
            document.getElementById("upld_" + f).remove();
          }
          else {
            document.getElementById("upld_" + f).classList.add("upload_error");
          }
        }
        catch(e) {
          console.log(e)
        }
        // hack; this should only be called once after all uploads has been completed
        this.$store.dispatch('jobs/retrieveJobsByStatus', 'all')
      }
    },
    async createQRFile(filename) {
      let blob = new Blob([ this.qr_content[filename] ], { type: "application/octet-stream"});

      let formData = new FormData();
      formData.append('files[]', blob, filename + '.txt');
      formData.append('job_id', this.job_id);
      formData.append('job_name', this.job_name);
      const URL = endpoint + '/api/v1/job/nematode/create';
      return await axios.post(URL, formData, { headers: authHeader() });
    },
    async send_file(file) {
      console.log('FILE UPLOAD')
      console.log(file)
      console.log('END UPLOAD')
      try {
        this.status = "uploading file " + file.name
          let formData = new FormData();
          formData.append('files[]', file);
          formData.append('job_id', this.job_id);
          formData.append('job_name', this.job_name);

          const URL = endpoint + '/api/v1/job/nematode/create';
          return await axios.post(URL, formData, { headers: authHeader() });
        }
      catch(E) {
        console.log(E)
        return
      }
    },
    reset() {
      Object.assign(this.$data, this.$options.data())
    },
    async getCameraSelection() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      const options = videoDevices.map(videoDevice => {
        return `<option value="${videoDevice.deviceId}">${videoDevice.label}</option>`;
      });
      document.getElementById('cameraselect').innerHTML = options.join('');
  },
    createCameraElement () {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: {
            exact: "environment"
          }
        },
        
        // deviceId: {
        //   exact: document.getElementById('cameraselect').value
        // }
      })
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream
        })
        .catch(error => {
          alert(error, "May the browser didn't support or there is some errors.")
        })
    },
    stopCameraStream () {
      const tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },
    toggleCamera () {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.stopCameraStream()
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
      }
    },
    async takePhoto () {
      this.isPhotoTaken = !this.isPhotoTaken
      this.pre_processing_started = true;

      const context = this.$refs.canvas.getContext('2d')
      const photoFromVideo = this.$refs.camera

      context.drawImage(photoFromVideo, 0, 0, 450, 337)
      let imageData = this.$refs.canvas.toDataURL('image/jpeg');
      let image =new Image('mobile_photo.JPG');
      image.src = imageData;
      this.addFile(image)

      this.pre_processing_done = true
    }
  }
}
</script>

<style scoped>
.img-fluid {
  width: 200px;
}
.container {
  position: relative;
  text-align: center;
}
.img-container {
  padding-right: 2px;
}
.centered {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 0.8em;
}

.v-progress-circular {
  margin: 1rem;
}

</style>