<template>
    <v-container>
      <v-card>
        <v-card-title>
          Job: {{ job_name }}
        </v-card-title>
        <v-card-subtitle>
          created: {{ job_date }}
        </v-card-subtitle>
        <v-card-subtitle>
          <strong>job status: <v-chip variant="flat">{{ job_status }}</v-chip></strong>
        </v-card-subtitle>
      <v-card-text>
        <v-divider></v-divider>

        <h3>Uploaded images</h3>
        
        <v-table>
          <thead>
            <tr>
              <th class="text-left">
                Image name
              </th>
              <th class="text-left">
                Upload date
              </th>
              <th>
                CSV
              </th>
              <th>
                Analyzed image
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="job in job_details"
              :key="job.image_id"
            >
              <td>{{ job.image_name }}</td>
              <td>{{ job.image_date }}</td>
              <td>
                <v-btn 
                  v-if="job_status=='finished'" 
                  variant="outlined"
                  size="small"
                  prepend-icon="mdi-file-download"
                  @click="downloadCSV( 'csv', job.image_name )"
                >
                  csv
                </v-btn>
              </td>
              <td>
                <v-btn 
                  v-if="job_status=='finished'" 
                  variant="outlined"
                  size="small"
                  prepend-icon="mdi-image-search"
                  @click="downloadCSV( 'image', job.image_name )"
                >
                  image
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          v-if="job_status=='initialized'" 
          variant="outlined"
          prepend-icon="mdi-auto-fix"
          @click="start_analysis()"
        >
          start analysis
        </v-btn>
        <v-btn 
          v-if="job_status=='finished'" 
          variant="outlined"
          prepend-icon="mdi-table"
          @click="downloadCSV('global_counts')"
        >
          global counts
        </v-btn>
        <v-btn 
          v-if="job_status=='finished'" 
          variant="outlined"
          prepend-icon="mdi-folder-zip"
          @click="downloadCSV('zip')"
        >
          all (zipped)
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: "job-details",
  data() {
    return {
      job_name: null,
      job_id: null,
      job_date: null,
      job_status: null,
    };
  },
  computed: {
    ...mapGetters('jobs',[
        'job_details'
    ]),
  },
  watch: {
    job_details() {
      this.job_name = this.job_details[0].job_name
      this.job_id = this.job_details[0].job_id
      this.job_date = this.job_details[0].job_date
      this.job_status = this.job_details[0].status
    }
  },
  mounted() {
    this.$store.dispatch('jobs/retrieveJobDetails', this.$route.params.job_id)
  },
  updated() {
    this.$store.dispatch('jobs/retrieveJobDetails', this.$route.params.job_id)
  },
  methods: { 
    start_analysis() {
      this.$store.dispatch('jobs/startJob', this.job_id)
      this.job_status = 'running'
    },
    downloadCSV(type, object) {
      this.$store.dispatch('jobs/retrieveObjectDetails', {job_id: this.job_id, object: object, type: type } )
    }
   }
};
</script>
