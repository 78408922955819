<template>
  <v-container>
    <v-card>
      <v-card-item>
        <v-card-title>Welcome {{ currentUser.username }}</v-card-title>

        <v-card-subtitle>{{ currentUser.email }}</v-card-subtitle>
      </v-card-item>
      <v-spacer></v-spacer>
      <v-card-text>
        <h1>Available Jobs</h1>

        <v-table>
          <thead>
            <tr>
              <th width="100">
              </th>
              <th class="text-left">
               Job name
              </th>
              <th class="text-left">
                Creation date
              </th>
              <th class="text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="job in jobs"
              :key="job.job_id"
            >
              <td>
              <v-btn 
                variant="outlined"
                prepend-icon="mdi-flask-outline"
                :color="job.status=='finished' ? 'success' : '' "
                size="small"
                :to="{ name: 'job', params: { job_id: job.job_id } }"
              >
                view
              </v-btn>
              </td>
              <td>{{ job.job_name }}</td>
              <td>{{ job.date }}</td>
              <td>
              <v-chip
                class="ma-2"
                :color="job.status=='finished' ? 'success' : 'primary' "
                variant="outlined"
                style="width: 130px;"
              >
                <v-icon 
                  start 
                  :icon="job.status=='finished' ? 'mdi-cloud-check-variant' : 'mdi-cog-pause-outline'"
                ></v-icon>
                {{job.status}}
              </v-chip>


              </td>
            </tr>
          </tbody>
        </v-table>


      </v-card-text>
    </v-card>



    <v-card class="mt-5">
      <v-card-title>Create new job</v-card-title>
      <v-card-subtitle>upload new images and create a new job</v-card-subtitle>
      <v-card-text>
        <v-btn
          color="primary"
           :to="{ name: 'upload' }"
           prepend-icon="mdi-cloud-upload"
        >
          upload
        </v-btn>
      </v-card-text>
    </v-card>


  </v-container>
  
</template>

<script>

import { mapGetters } from 'vuex';


export default {
  name: 'HomePage',
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('auth',[
        'currentUser'
    ]),
    ...mapGetters('jobs',[
        'jobs',
    ]),
  },
}
</script>

<style scoped>

</style>
